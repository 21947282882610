@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Urbanist', sans-serif;
}

.container{
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

section{
  padding: 50px 0;
}

.login{
  height: calc(100vh - 60px)
}

.sticky__header{
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 3px 3px -8px 3px #ddd;
  z-index: 99;
}
.calc{
  height: calc(100vh - 60px);
}


